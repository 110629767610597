import { USER_SUCCESS, USER_LOGOUT } from '../actions/user'

const state = {
  userGroups: localStorage.getItem('agedati-user-group') || '',
  userFirstName: localStorage.getItem('agedati-user-first_name') || '',
  userLastName: localStorage.getItem('agedati-user-last_name') || '',
  userUsername: localStorage.getItem('agedati-user-username') || '',
}

const getters = {
  getUserGroups: state => state.userGroups,
  getUserFirstName: state => state.userFirstName,
  getUserLastName: state => state.userLastName,
  getUserUsername: state => state.userUsername,

}

const actions = {
  [USER_LOGOUT]: ({commit}) => {
    commit(USER_LOGOUT)
  }
}

const mutations = {
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.userGroups = JSON.stringify(resp.groups)
    localStorage.setItem('agedati-user-group', JSON.stringify(resp.groups))
    state.userFirstName = resp.first_name
    localStorage.setItem('agedati-user-first_name', resp.first_name)
    state.userLastName = resp.last_name
    localStorage.setItem('agedati-user-last_name', resp.last_name)
    state.userUsername = resp.username
    localStorage.setItem('agedati-user-username', resp.username)

  },
  [USER_LOGOUT]: (state) => {
    state.userGroups = ''
    localStorage.removeItem('agedati-user-group')
    state.userFirstName = ''
    localStorage.removeItem('agedati-user-first_name')
    state.userLastName = ''
    localStorage.removeItem('agedati-user-last_name')
    state.userUsername = ''
    localStorage.removeItem('agedati-user-username')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
