import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {

  conseillerDashboardTabIndex: 0,

  managerDashboardTabIndex: 0,

  // ---------- CONSEILLER ACCORDS -------------
  conseillerDashboardAllAccordsOngoingNotAttributedFilter: true,
  conseillerDashboardAllAccordsState: '',
  conseillerDashboardAllAccordsByStateFilter: false,
  conseillerDashboardOwnAccordsState: '',
  conseillerDashboardOwnAccordsByStateFilter: false,
  conseillerDashboardSearchAccordsMuna: '',
  conseillerDashboardAccordsIsMunaFilter: false,
  conseillerDashboardSearchAccordsName: '',
  conseillerDashboardIsAccordsNameFilter: false,

  // --------- MANAGER ACCORDS ----------------
  managerDashboardAllAccordsState: '5CVALIDATED',
  managerDashboardAllAccordsByStateFilter: true,
  managerDashboardSearchAccordsMuna: '',
  managerDashboardAccordsIsMunaFilter: false,
  managerDashboardSearchAccordsName: '',
  managerDashboardIsAccordsNameFilter: false,

  // ---------- CONSEILLER REMBOURSEMENTS -------------
  conseillerDashboardAllRemboursementsNotAttributedState: '2ONGOING',
  conseillerDashboardAllRemboursementsNotAttributedByStateFilter: true,
  conseillerDashboardAllRemboursementsState: '',
  conseillerDashboardAllRemboursementsByStateFilter: false,
  conseillerDashboardOwnRemboursementsState: '',
  conseillerDashboardOwnRemboursementsByStateFilter: false,
  conseillerDashboardSearchRemboursementsMuna: '',
  conseillerDashboardRemboursementsIsMunaFilter: false,
  conseillerDashboardSearchRemboursementsName: '',
  conseillerDashboardIsRemboursementsNameFilter: false,

  // --------- MANAGER REMBOURSEMENTS ----------------
  managerDashboardAllRemboursementsState: '5CVALIDATED',
  managerDashboardAllRemboursementsByStateFilter: true,
  managerDashboardSearchRemboursementsMuna: '',
  managerDashboardRemboursementsIsMunaFilter: false,
  managerDashboardSearchRemboursementsName: '',
  managerDashboardIsRemboursementsNameFilter: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
}

export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  strict: debug,
  state,
  mutations
})
