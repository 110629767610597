<template>
  <div id="app">
    <b-navbar class="base_navbar"  toggleable="md">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand :to="{ path: '/' }">
        <img id='brand_logo' :src="require('@/assets/images/logo_agedati.png')" alt="Audiens - Plateforme d'aide à la garde d'enfants">
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse" >
        <b-navbar-nav v-if="isAuthenticated" class="ml-auto">
          <b-nav-item v-if="userGroupContains(['BENEFICIAIRE'])" class="navbar_items" to="/beneficiaire-dashboard"> Dépôt et suivi de mes demandes </b-nav-item>
          <b-nav-item v-if="userGroupContains(['MANAGER'])" class="navbar_items" to="/manager-dashboard"> Tableau de bord Manager</b-nav-item>
          <b-nav-item v-if="userGroupContains(['CONSEILLER'])" class="navbar_items" to="/conseiller-dashboard"> Tableau de bord Conseiller</b-nav-item>
          <b-nav-item v-if="userGroupContains(['MANAGER']) && canAccessStatistics()" class="navbar_items" to="/manager-statistiques"> Statistiques</b-nav-item>
          <b-nav-item-dropdown  class="navbar_items" text="Profil" right>
            <b-dropdown-item v-if="userGroupContains(['BENEFICIAIRE'])"  class="navbar_dropdown_items" to="/settings/profile-edition">Editer votre profil</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/settings/modify-password">Modifier votre mot de passe</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="userGroupContains(['BENEFICIAIRE'])" class="navbar_items" text="Aide" right>
            <b-dropdown-item  class="navbar_dropdown_items" to="/aide">Aide en ligne</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="navbar_items" to="/logout">Se déconnecter</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-else class="ml-auto">
          <b-nav-item-dropdown class="navbar_items" text="Aide" right>
            <b-dropdown-item  class="navbar_dropdown_items" to="/aide">Aide en ligne</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="navbar_items" to="/login">Se connecter</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
import { authorizedStatisticsManager } from '@/variables/localVariables'
import { userMixins } from '@/mixins/userMixins'

export default {
  name: 'App',
  mixins: [userMixins],
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  },
  methods: {
    canAccessStatistics() {
      var username = this.$store.getters.getUserUsername
      var is_contained = false

      for (var j = 0; j < authorizedStatisticsManager.length; j++) {
        if (authorizedStatisticsManager.indexOf(username) > -1) {
          is_contained = true
        }
      }
      return is_contained
    }
  }
}
</script>

<style>
html, body { overflow-x: hidden; }


body {
  font-family: Open Sans, sans-serif !important;
  padding-right: 0 !important;
}
.masthead {
  padding-top: 2%;
  padding-bottom: 2%;
}

.justify-text {
  text-align: justify;
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */

}

.base_navbar {
  height: 80px;
  background-color: white;
}
#brand_logo {
  height: 50px;
}

#nav_collapse {
  background-color: white;
  z-index: 1001;
}

.navbar_items {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.nav-link {
  color: rgba(0, 0, 0, 1) !important;
}


@media (min-width: 480px) {
  #brand_logo {
    height: 70px;
  }
  .base_navbar {
    height: 90px;
  }

}

.audiens-color {
  color: #BB3634
}

@media (min-width: 1024px) {
  .base_navbar {
    height: 110px !important;
  }

  #brand_logo {
    height: 100px;
  }
  .navbar_items {
    font-size: 18px;
  }
}

div.mandatory-input label:after {
  content:"*";
  color:red;
}

.form_div {
  background: #FFFFFF;
  padding-left: 15px;
  padding-bottom: 20px;
  border-style: solid;
  border-color: #BDBDBD;
  border-width: 0.5px;
  margin-top: 15px;
}

.form_div_title{
  color: #FFFFFF;
  font-size: calc(1.2em + 0.8vw);
  margin-top: -90px;
  font-family: Montserrat Subrayada, sans-serif !important;
}

.form_content {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.form_content h2 {
  color: #454F59;
  margin-top:30px;
  font-weight: bold;
  font-size: calc(1.1em + 0.1vw);
}

.form_content h3 {
  font-weight: bold;
  color: #6E6E6E;
  margin-top:10px;
  font-size: calc(0.9em + 0.4vw);
}

.card-header {
  font-weight: bold;
  color: #B43104;
  font-size: calc(0.9em + 0.5vw);
}

.form-error {
  color: #B1060A;
  font-size: 0.8em;
  font-style: italic;
}

.footer-items {
  font-size: 1.2em;
}

.presentation_content h1 {
  font-weight: bold;
  color: #B43104;
  margin-top:10px;
  font-size: calc(0.9em + 0.5vw);
}

.presentation_content h2 {
  font-weight: bold;
  color: #B43104;
  margin-top:10px;
  font-size: calc(0.8em + 0.4vw);
}

.abattement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.abattement-item--close-window {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid red;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abattement-item--close-window--cross {
  position: absolute;
  font-size: 12px;
  color: red;
  cursor: pointer;
}

.frais-supplementaires--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.frais-supplementaires--item--close-window {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid red;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frais-supplementaires--item--close-window--cross {
  position: absolute;
  font-size: 12px;
  color: red;
  cursor: pointer;
}

.frais-supplementaires--add {
  display: flex;
  align-items: center;
  gap: 10px;
}

</style>
