import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import Home from '../views/Home.vue'

const Eligibility = () => import('../views/Eligibility')
const BeneficiaireRegister = () => import('../views/user/BeneficiaireRegister')
const Login = () => import('../views/user/Login')
const PasswordReset = () => import('../views/settings/PasswordReset')
const PasswordResetConfirm = () => import('../views/settings/PasswordResetConfirm')

// ---------- USER CONNECTED ------------------------
const ModifyPassword = () => import('../views/settings/ModifyPassword')
const Logout = () => import('../views/user/Logout')

// ---------- CONSEILLER CONNECTED -----------------
const ConseillerDashboard = () => import('../views/conseiller/ConseillerDashboard')
const ConseillerAccordProfile = () => import('../views/conseiller/ConseillerAccordProfile')
const ConseillerRemboursementProfile = () => import('../views/conseiller/ConseillerRemboursementProfile')

// ---------- MANAGER CONNECTED ---------------------
const ManagerDashboard = () => import('../views/manager/ManagerDashboard')
const ManagerAccordProfile = () => import('../views/manager/ManagerAccordProfile')
const ManagerRemboursementProfile = () => import('../views/manager/ManagerRemboursementProfile')
const ManagerStatistics = () => import('../views/manager/ManagerStatistics')

// ---------- CONSEILLER OR MANAGER CONNECTED --------
const ConseillerManagerBeneficiaireDashboard = () => import('../views/conseiller/ConseillerManagerBeneficiaireDashboard')


// ---------- BENEFICIAIRE CONNECTED ----------------
const BeneficiaireProfileEdition = () => import('../views/user/BeneficiaireProfileEdition')
const BeneficiaireDashboard = () => import('../views/user/BeneficiaireDashboard')
const AccordDetails = () => import('../views/user/AccordDetails')
const RemboursementDetails = () => import('../views/user/RemboursementDetails')

const notFound = () => import('../views/notFound')
const Help = () => import('../views/Help')
const ConfidentialityPolitique = () => import('../views/information/ConfidentialityPolitique')
const MentionsLegales = () => import('../views/information/MentionsLegales')
const ConditionsUtilisation = () => import('../views/information/ConditionsUtilisation')

Vue.use(VueRouter)


const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const isUserGroup = (group_name) => {
  var userGroups = store.getters.getUserGroups
  var userGroupsArray = []
  var is_contained = false
  if (userGroups) {
    try {
      var userGroupsJson = JSON.parse(userGroups)
      for (var i = 0; i < userGroupsJson.length; i++) {
        userGroupsArray.push(
          userGroupsJson[i]['name']
        )
      }
    }
    catch(error) {
      return is_contained
    }
  }

  for (var j = 0; j < group_name.length; j++) {
    if (userGroupsArray.indexOf(group_name[j]) > -1) {
      is_contained = true
    }
  }
  return is_contained
}

const isConnectedBeneficiaire = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['BENEFICIAIRE'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}


const isConnectedConseiller = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['CONSEILLER'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}


const isConnectedManager = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['MANAGER'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}

const isConnectedConseillerOrManager = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['MANAGER']) || isUserGroup(['CONSEILLER'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // ------------ NOT CONNECTED ----------------
  {
    path: '/eligibilite',
    name: 'Eligibility',
    component: Eligibility,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/creation-compte',
    name: 'BeneficiaireRegister',
    component: BeneficiaireRegister,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/settings/reset-password',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/settings/reset-password-confirm',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    beforeEnter: ifNotAuthenticated
  },

  // ----------- USER CONNECTED ----------------
  {
    path: '/settings/modify-password',
    name: 'ModifyPassword',
    component: ModifyPassword,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated
  },

  // ----------- BENEFICIAIRE CONNECTED --------
  {
    path: '/settings/profile-edition',
    name: 'BeneficiaireProfileEdition',
    component: BeneficiaireProfileEdition,
    beforeEnter: isConnectedBeneficiaire
  },
  {
    path: '/beneficiaire-dashboard',
    name: 'BeneficiaireDashboard',
    component: BeneficiaireDashboard,
    beforeEnter: isConnectedBeneficiaire
  },
  {
    path: '/accord/:accord_id',
    name: 'AccordDetails',
    component: AccordDetails,
    beforeEnter: isConnectedBeneficiaire
  },
  {
    path: '/remboursement/:remboursement_id',
    name: 'RemboursementDetails',
    component: RemboursementDetails,
    beforeEnter: isConnectedBeneficiaire
  },

  // ------------ CONSEILLER CONNECTED ----------
  {
    path: '/conseiller-dashboard',
    name: 'ConseillerDashboard',
    component: ConseillerDashboard,
    beforeEnter: isConnectedConseiller
  },
  {
    path: '/conseiller-dashboard/accord/:accord_id',
    name: 'ConseillerAccordProfile',
    component: ConseillerAccordProfile,
    beforeEnter: isConnectedConseiller
  },
  {
    path: '/conseiller-dashboard/remboursement/:remboursement_id',
    name: 'ConseillerRemboursementProfile',
    component: ConseillerRemboursementProfile,
    beforeEnter: isConnectedConseiller
  },

  // ------------ MANAGER CONNECTED ----------
  {
    path: '/manager-dashboard',
    name: 'ManagerDashboard',
    component: ManagerDashboard,
    beforeEnter: isConnectedManager
  },
  {
    path: '/manager-dashboard/accord/:accord_id',
    name: 'ManagerAccordProfile',
    component: ManagerAccordProfile,
    beforeEnter: isConnectedManager
  },
  {
    path: '/manager-dashboard/remboursement/:remboursement_id',
    name: 'ManagerRemboursementProfile',
    component: ManagerRemboursementProfile,
    beforeEnter: isConnectedManager
  },
  
  {
    path: '/manager-statistiques',
    name: 'ManagerStatistics',
    component: ManagerStatistics,
    beforeEnter: isConnectedManager
  },
  
  
  // ----------- CONSEILLER OR MANAGER CONNECTED -------------
  {
    path: '/conseiller-manager-dashboard/beneficiaire/:beneficiaire_id',
    name: 'ConseillerManagerBeneficiaireDashboard',
    component: ConseillerManagerBeneficiaireDashboard,
    beforeEnter: isConnectedConseillerOrManager
  },


  // ------------ ALL ----------------
  {
    path: '/404',
    name: 'notFound',
    component: notFound,
  },
  {
    path: '/aide',
    name: 'Help',
    component: Help,
  },
  {
    path: '/politique-de-confidentialite',
    name: 'ConfidentialityPolitiqueHelp',
    component: ConfidentialityPolitique,
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: MentionsLegales,
  },
  {
    path: '/conditions-utilisations',
    name: 'ConditionsUtilisation',
    component: ConditionsUtilisation,
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
