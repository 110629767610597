import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuex from 'vuex'


import {
  LayoutPlugin, ModalPlugin, DropdownPlugin,
  NavPlugin, NavbarPlugin, ButtonPlugin,
  FormPlugin, FormCheckboxPlugin, FormGroupPlugin,
  FormInputPlugin, InputGroupPlugin, FormSelectPlugin,
  FormFilePlugin, FormTextareaPlugin, SpinnerPlugin,
  TablePlugin, TabsPlugin, ImagePlugin, FormRadioPlugin,
  FormDatepickerPlugin, PopoverPlugin, ToastPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/dot-circle'
import 'vue-awesome/icons/info-circle'
import 'vue-awesome/icons/file-pdf'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/check-circle'
import 'vue-awesome/icons/trash-alt'
import 'vue-awesome/icons/cloud-download-alt'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/undo'
import 'vue-awesome/icons/plus'


import Icon from 'vue-awesome/components/Icon'

import browserDetect from "vue-browser-detect-plugin";

import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import VueGtag from "vue-gtag";

import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-161958571-5" }
}, router);

Vue.use(Vuex)

Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(PopoverPlugin)
Vue.use(ToastPlugin)

Vue.component('v-icon', Icon)

Vue.use(browserDetect)

Vue.use(VueDayjs)

Vue.use(VueMask)

Vue.component('loading', Loading)

Vue.component('vue-multiselect-input', Multiselect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
